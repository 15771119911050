import { Container, Col, Row } from 'react-bootstrap';
import "../HowItWorks/css/how-it-works.css";
import game from "../../../assets/images/how_it_works/games.png";
import computer_vision from "../../../assets/images/how_it_works/computer_vision.png";
import ml_models from "../../../assets/images/how_it_works/ml_models.png";
import raw_data from "../../../assets/images/how_it_works/raw_data_and_analytics.png";
import right_arrow from "../../../assets/images/how_it_works/right.png";
import down_arrow from "../../../assets/images/how_it_works/down.png";
import "@fontsource/dm-sans";

function HowItWorks() {
    return (
        <div className="how-it-works" id="how-it-works">
            <Container className="how-it-works-container">
                <Row className="how-header-row">
                    <Col xs={12}>
                        <p className="how-header">HOW IT WORKS</p>
                        <p className="how-header-2">Esports Data Reimagined</p>
                        <p className='how-header-text'>
                            Rimble's platform ingests vast amounts of historical data and live data generated with our proprietary computer vision technology. We then use custom built machine learning and automation to create our analytics and data feeds for micro events.
                        </p>
                    </Col>
                </Row>
                <Row className="how-icons">
                    <Col className="icon-step">
                        <div className='step-img-container'>
                            <img className="game-image" src={game} loading="lazy" decoding="async" alt="Game" />
                        </div>
                        <p className="icon-desc game-text">Game</p>
                    </Col>
                    <Col className="arrow-icon right-arrow">
                        <img className="arrow" src={right_arrow} loading="lazy" decoding="async" alt="Arrow" />
                    </Col>
                    <Col className="arrow-icon down-arrow">
                        <img className="arrow" src={down_arrow} loading="lazy" decoding="async" alt="Arrow" />
                    </Col>
                    <Col className="icon-step">
                        <div className='step-img-container'>
                            <img className="computer-vision" src={computer_vision} loading="lazy" decoding="async" alt="Computer Vision" />
                        </div>
                        <p className='icon-desc computer-text'>Computer Vision</p>
                    </Col>
                    <Col className="arrow-icon right-arrow">
                        <div className='step-img-container'>
                            <img className="arrow" src={right_arrow} loading="lazy" decoding="async" alt="Arrow" />
                        </div>
                    </Col>
                    <Col className="arrow-icon down-arrow">
                        <img className="arrow" src={down_arrow} loading="lazy" decoding="async" alt="Arrow" />
                    </Col>
                    <Col className="icon-step">
                        <div className='step-img-container'>
                            <img className="ml-model" src={ml_models} loading="lazy" decoding="async" alt="ML Models" />
                        </div>
                        <p className='icon-desc ml-text'>ML Models</p>
                    </Col>
                    <Col className="arrow-icon right-arrow">
                        <img className="arrow" src={right_arrow} loading="lazy" decoding="async" alt="Arrow" />
                    </Col>
                    <Col className="arrow-icon down-arrow">
                        <img className="arrow" src={down_arrow} loading="lazy" decoding="async" alt="Arrow" />
                    </Col>
                    <Col className="icon-step">
                        <div className='step-img-container'>
                            <img className="raw-data" src={raw_data} loading="lazy" decoding="async" alt="Raw Data & Analytics" />
                        </div>
                        <p className='icon-desc raw-text'>Raw Data & Analytics</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HowItWorks;

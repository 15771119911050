import './App.css';
import { HashRouter, Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import LandingPage from "./pages/LandingPage.js"
import About from './pages/About.js';
import Terms from './pages/Terms.js';
import Dashboard from './pages/Dashboard.js';
import Teams from './pages/Teams.js';
import Players from './pages/Players.js';
import "@fontsource/dm-sans";
import React from 'react';
import PBDashboard from './pages/PBDashboard';
import PBMatch from './pages/PBMatch';
import Privacy from './pages/Privacy.js';
import Media from './pages/Media.js';
import ArticleOne from './pages/ArticleOne.js';
import ArticleTwo from './pages/ArticleTwo.js';
import ArticleThree from './pages/ArticleThree.js';

function App() {

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/media" element={<Media />}></Route>
          <Route path="/analyticsimportance" element={<ArticleOne />}></Route>
          <Route path="/esportsbettingexplained" element={<ArticleTwo />}></Route>
          <Route path="/esportsrisksmechanics" element={<ArticleThree />}></Route>
          <Route path="/privacypolicy" element={<Privacy />}></Route>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/teams/:game_type/:team_1/:team_2/:timeframe/:matchid" element={<Teams />} />
          <Route exact path="/players/:game_type/:team_1/:team_2/:team_num/:timeframe/:matchid/:player" element={<Players />} />
          <Route exact path="/peel-blue-dashboard" element={<PBDashboard />} />
          <Route exact path="/peel-blue-match/:game_type/:matchid/:date/:match_type" element={<PBMatch />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;

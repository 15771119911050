import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../WhyRimble/css/why-rimble.css";
import advanced_micro from "../../../assets/images/why_rimble/advanced_micro_analytics.png";
import unique_data from "../../../assets/images/why_rimble/unique_data_generation.png";
import real_time from "../../../assets/images/why_rimble/real_time_odds_support.png";

function WhyRimble() {
    return (
        <div className="why-rimble" id="why-rimble">
            <Container className="why-rimble-container">
                <Row className="why-header-container">
                    <p className="why-header">WHY RIMBLE</p>
                    <p className="why-header-2">Industry Leading Support</p>
                </Row>
                <Row className="why-us-icons">
                    <Col className="why-us-icon-container">
                        <img className="why-icon-image" src={advanced_micro} loading="lazy" decoding="async" alt="Real Time Micro-Analytics" />
                        <h1 className="why-icon-heading">SGP + Micro markets</h1>
                        <p className="why-icon-text">Rimble offers a deeper level of fan engagement by focusing on markets fans care about.</p>
                    </Col>
                    <Col className="why-us-icon-container">
                        <img className="why-icon-image" src={unique_data} loading="lazy" decoding="async" alt="Unique Data Generation" />
                        <h1 className="why-icon-heading">Unique Data Access</h1>
                        <p className="why-icon-text">Powered by official data wherever possible, Rimble also has exclusive streamer feeds to enhance your existing operations.</p>
                    </Col>
                    <Col className="why-us-icon-container">
                        <img className="why-icon-image" src={real_time} loading="lazy" decoding="async" alt="Ease of Integration" />
                        <h1 className="why-icon-heading">Ease of Integration</h1>
                        <p className="why-icon-text">Our APIs and white-label experiences are designed to reduce development hassle and ease integration.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WhyRimble;

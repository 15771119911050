import React, { useState } from 'react';

import { Nav } from 'react-bootstrap';
import { HashLink } from "react-router-hash-link";

import "../NavBar/css/Navbar.css";

function CustomNavbar() {
    const [isOpen, setIsOpen] = useState(false);
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener, noreferrer');
    }


    const toggleNavbar = () => {

        setIsOpen(prevState => !prevState);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-brand"><a href="">Rimble</a></div>
                <div className={`navbar-toggle ${isOpen ? 'open' : ''}`} onClick={toggleNavbar}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <div className={`navbar-links ${isOpen ? 'active' : ''}`}>

                    <Nav.Link className="navbar-link" onClick={() => openInNewTab("https://documenter.getpostman.com/view/16449503/Tzm8FvFw")}>API Documentation</Nav.Link>
                    <Nav.Link className="navbar-link" href="/#/dashboard">Dashboard</Nav.Link>
                    <Nav.Link className="navbar-link" href="/#/about">About</Nav.Link>
                    <Nav.Link className="navbar-link" href="/#/media">Media</Nav.Link>
                    <HashLink className="navbar-link" smooth to={"/#contact-us"}>Contact Us</HashLink>
                </div>
            </div>
        </nav>


    );
}

export default CustomNavbar;

import "../Footer/css/footer.css";
import { HashLink } from "react-router-hash-link";

function Footer() {
    return (
        <footer className="footer-hold" id="footer">
            <div className="footer-container">
                <div className="footer-links-container">

                    <div className="footer-links">
                        <div className="footer-header-links">
                            <h1 className="footer-rimble-header">Rimble</h1>
                            <p className="address-footer">
                                San Francisco HQ
                                <br />
                                149 New Montgomery St, 4th Floor
                                <br />
                                San Francisco, CA 94105
                            </p>
                        </div>
                    </div>
                    <div className="footer-links">
                        <strong className="footer-text-links">Product</strong>
                        <HashLink className="footer-link" smooth to={"/#how-it-works"}>
                            How it Works
                        </HashLink>
                        <a className="footer-link" href="https://documenter.getpostman.com/view/16449503/Tzm8FvFw" target="_blank" rel="noopener noreferrer">
                            API Documentation
                        </a>
                    </div>
                    <div className="footer-links">
                        <strong className="footer-text-links">Company</strong>
                        <HashLink className="footer-link" smooth to={"/about/#our-vision"}>
                            Our Mission
                        </HashLink>
                        <HashLink className="footer-link" smooth to={"/#why-rimble"}>
                            Why Rimble
                        </HashLink>
                    </div>
                    <div className="footer-links">
                        <strong className="footer-text-links">Resources</strong>
                        <HashLink className="footer-link" smooth to={"/privacypolicy/#policy"}>
                            Privacy Policy
                        </HashLink>
                        <HashLink className="footer-link" smooth to={"/terms/#ourterms"}>
                            Terms
                        </HashLink>
                    </div>
                    <div className="footer-links">
                        <strong className="footer-text-links">Contact Us</strong>
                        <HashLink className="footer-link" smooth to={"/#contact-us"}>
                            Get Started
                        </HashLink>
                        <a className="footer-link" href="mailto:support@rimble.io">
                            support@rimble.io
                        </a>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-text">&copy; Copyright 2024. All rights reserved</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

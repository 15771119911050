import Layout from "../components/Layout.js"
import MediaReleases from "../components/MediaReleases/mediareleases.js";
import "../components/MediaReleases/css/mediareleases.css"

function Media() {
    return (
        <Layout>
            <MediaReleases />
        </Layout>
    )
}

export default Media;
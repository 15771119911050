import React, { useState } from "react";
import "../SupportGames/css/supported-games.css";
import { Container, Row, Col } from "react-bootstrap";
import GameCard from "../../GameCard/GameCard.js";
import GameCardData from "../../GameCard/GameCardData.js";

// Import images
import lolSelected from "../../../assets/images/supported_games/league_selected.png";
import lolNotSelected from "../../../assets/images/supported_games/league_not_selected.png";
import dota2Selected from "../../../assets/images/supported_games/dota2_selected.png";
import dota2NotSelected from "../../../assets/images/supported_games/dota2_not_selected.png";
import csgoSelected from "../../../assets/images/supported_games/csgo_selected.png";
import csgoNotSelected from "../../../assets/images/supported_games/csgo_not_selected.png";
import codSelected from "../../../assets/images/supported_games/call_of_duty_selected.png";
import codNotSelected from "../../../assets/images/supported_games/call_of_duty_not_selected.png";
import valSelected from "../../../assets/images/supported_games/valorant_selected.png";
import valNotSelected from "../../../assets/images/supported_games/valorant_not_selected.png";
import rocketSelected from "../../../assets/images/supported_games/rocket_league_selected.png";
import rocketNotSelected from "../../../assets/images/supported_games/rocket_league_not_selected.png";

const images = {
    lol: { selected: lolSelected, notSelected: lolNotSelected },
    dota2: { selected: dota2Selected, notSelected: dota2NotSelected },
    csgo: { selected: csgoSelected, notSelected: csgoNotSelected },
    cod: { selected: codSelected, notSelected: codNotSelected },
    val: { selected: valSelected, notSelected: valNotSelected },
    rl: { selected: rocketSelected, notSelected: rocketNotSelected },
};

function SupportedGames() {
    const [active, setActive] = useState("lol");

    const activeHandler = (event) => {
        setActive(event.target.id); // Update active game based on clicked icon
    };

    const gameData = GameCardData.find((game) => game.id === active);

    return (
        <div className="supported-games">
            <Container className="supported-games-container">
                <Row className="sg-header-container">
                    <Col>
                        <p className="sg-header">SUPPORTED GAMES</p>
                        <p className="sg-header-2">Extensive coverage</p>
                        <p className="sg-header-text">Click on the game to view the stats!</p>
                    </Col>
                </Row>
                <Row className="sg-content-container">
                    <Col className="sg-icons">
                        {Object.keys(images).map((game) => (
                            <div className="game-icon-container" key={game}>
                                <img
                                    id={game}
                                    src={active === game ? images[game].selected : images[game].notSelected}
                                    className={`game-icon ${active === game ? "game-icon-a" : ""}`}
                                    alt={game.charAt(0).toUpperCase() + game.slice(1)}
                                    onClick={activeHandler}
                                />
                                <p className={`game-info-text ${active === game ? "game-info-text-a" : ""}`}>
                                    {game.toUpperCase()}
                                </p>
                            </div>
                        ))}
                    </Col>
                    <Col className="game-card-container">
                        {gameData && <GameCard data={gameData} />}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SupportedGames;
